import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
//import {Spinner} from "react-bootstrap";
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Homepage from './views/Homepage';
import Demos from './views/Demos';
import About from './views/About';
import './custom.scss';
import './App.scss';

//const loading = <Spinner animation="grow" variant="light" />;

export class App extends React.PureComponent {
  render() {
    return (
      <div>
        <HashRouter>
          <div className="nav-shadow">
            <Navigation />
          </div>
          <Switch>
            <Route path="/" exact>
              <header className={'App-header'}>
                <Homepage />
              </header>
            </Route>
            <Route path="/demo" exact>
              <header className={'App-header'}>
                <Demos />
              </header>
            </Route>
            <Route path="/about" exact>
              <header className={'App-header'}>
                <About />
              </header>
            </Route>
          </Switch>
          <div className="nav-shadow">
            <Footer email={'chrisgraneyward@gmail.com'} />
          </div>
        </HashRouter>
      </div>
    );
  }
}

export default App;
