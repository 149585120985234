import React, { Component } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import requests from '../requests';

export default class Demos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      responseJson: '',
    };
    this.collectTwitterPosts = this.collectTwitterPosts.bind(this);
  }

  collectTwitterPosts(type) {
    requests.predictHateTwitter(this.state.input, type).then((r) => {
      this.setState({ responseJson: JSON.stringify(r.data, null, 2) });
    });
  }

  updateUser(input) {
    this.setState({ input });
  }

  render() {
    return (
      <Container>
        <Form>
          <Form.Group>
            <Form.Control
              className="mt-4"
              placeholder={'Enter a twitter username or hashtag...'}
              value={this.state.input}
              onChange={(e) => {
                this.updateUser(e.target.value);
              }}
            />
            <Button
              className="btn-outline-secondary my-4"
              onClick={() => {
                this.collectTwitterPosts('user');
              }}
            >
              Search Username
            </Button>
            <Button
              className="btn-outline-secondary my-4 ml-4"
              onClick={() => {
                this.collectTwitterPosts('hashtag');
              }}
            >
              Search Hashtag
            </Button>
          </Form.Group>
        </Form>
        <code>
          <pre>{this.state.responseJson}</pre>
        </code>
      </Container>
    );
  }
}
