import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RAPIDAPI_PROJECT_PAGE } from '../consts';

export default class Navigation extends Component {
  render() {
    return (
      <Navbar
        sticky="top"
        collapseOnSelect
        expand="lg"
        bg="secondary"
        className="corner-cut-bl"
      >
        <Navbar.Brand>
          <Link to="/" className={'text-primary'}>
            CBAD AI
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/demo" className={'nav-link text-primary'}>
              Demos
            </Link>
            <a href={RAPIDAPI_PROJECT_PAGE} className={'nav-link text-primary'}>
              API
            </a>
            <Link to="/about" className={'nav-link text-primary'}>
              About
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
