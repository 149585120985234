import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './index.css';
import App from './App';
// import ReactGA from 'react-ga';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactGA.initialize('UA-178407039-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
