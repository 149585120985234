import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { RAPIDAPI_PROJECT_PAGE } from '../consts';

export default class About extends Component {
  render() {
    return (
      <Container>
        This app communicates with a state of the art hate speech detection
        model.
        <br />
        It uses the BERTweet Transformer model as a base, it is then trained on
        200k hatespeech and normal speech tweets. The model is proprietary and
        at time of creation is one of the most performant models in the world.
        <hr />
        The API is a GPU accellerated, python FastAPI stack with a Caddy
        webserver. It is orchestrated by docker compose so the whole thing can
        be pulled up with a single command. It serves the AI model mentioned
        above.
        <hr />
        The website is created using React best practices and hosted on Cloudflare.
        <hr />
        <a className='bg-dark' href={RAPIDAPI_PROJECT_PAGE}>Click here if you would like access to the API.</a>
      </Container>
    );
  }
}
