import React, { Component } from 'react';
import { Container, Button, Spinner, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BASE_URL } from './../consts';
import requests from '../requests';

export default class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholderSentences: [
        'Write bullying or hateful speech...',
        'Write full sentences as if texting or posting to social media...',
        'Try to trick the AI...',
        "Using hateful keywords alone probably won't trick the AI...",
        'The AI makes its predictions based on context derived from the rest of the text.',
      ],
      placeholderText: '',
      toPredict: '',
      classification: '',
      probability: '',
      wordCloud: '',
      lastPrediction: '',
      explainedPrediction: '',
      explainedLoading: false,
    };
  }

  componentDidMount() {
    this.ghostWriter();
    this.autoUpdater();
  }

  async autoUpdater() {
    while (true) {
      await this.sleep(2000);
      if (this.state.toPredict !== this.state.lastPrediction) {
        this.setState({ lastPrediction: this.state.toPredict });
        this.getPrediction();
      }
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async ghostWriter() {
    while (true) {
      for (const sentence of this.state.placeholderSentences) {
        for (const char of sentence.split('')) {
          await this.setState({
            placeholderText: this.state.placeholderText + char,
          });
          await this.sleep(50);
        }
        await this.sleep(1000);
        while (this.state.placeholderText.length > 0) {
          await this.setState({
            placeholderText: this.state.placeholderText.slice(0, -1),
          });
          await this.sleep(10);
        }
      }
    }
  }

  getPrediction() {
    requests.predictHate(this.state.toPredict).then((r) => {
      this.setState({
        classification: r.data.isHate,
        probability: (r.data.probability * 100).toFixed(2) + '%',
      });
    });
  }

  getExplainedPrediction() {
    this.setState({
      explainedLoading: true,
    });
    try {
      requests.predictHateExplain(this.state.toPredict).then((r) => {
        this.setState({
          explainedPrediction: r.data,
          explainedLoading: false,
        });
      });
    } catch {
      this.setState({
        explainedLoading: false,
      });
    }
  }

  updateText(text) {
    this.setState({ toPredict: text });
  }

  getWordCloud() {
    this.setState({
      wordCloud: `${BASE_URL}/visual/wordcloud?text=${this.state.toPredict}`,
    });
  }

  render() {
    return (
      <Container>
        <h3 className="mt-4">{this.state.classification}</h3>
        <h3>{this.state.probability}</h3>
        <div className="nav-shadow">
          <textarea
            onChange={(e) => this.updateText(e.target.value)}
            rows="10"
            style={{ width: '100%' }}
            placeholder={this.state.placeholderText}
            value={this.state.toPredict}
          ></textarea>
        </div>
        <Button
          className="btn-outline-secondary my-4"
          onClick={() => {
            this.getWordCloud();
          }}
        >
          Generate Wordcloud
        </Button>
        <Button
          className="btn-outline-secondary my-4 ml-4"
          onClick={() => {
            this.getExplainedPrediction();
          }}
          disabled={this.state.explainedLoading}
        >
          {this.state.explainedLoading && (
            <Spinner
              animation="grow"
              role="status"
              size="sm"
              className="mr-2"
            />
          )}
          Generate Explained Prediction
        </Button>
        <Link to="/demo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button className="btn-secondary my-4">Try the Twitter Demo</Button>
        </Link>
        <Row>
          <Col size={6}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.explainedPrediction,
              }}
            />
          </Col>
          <Col size={6}>
            <img
              src={this.state.wordCloud}
              alt={''}
              width="100%"
              className="mb-4"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
