import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RAPIDAPI_PROJECT_PAGE } from '../consts';

export default class Footer extends Component {
  render() {
    return (
      <footer className="bg-secondary p-4 sticky-bottom corner-cut-tr">
        <Row>
          <Col sm={1}></Col>
          <Col sm={4} className={'pb-4'}>
            <h6>Contact and information</h6>
            <ul>
              <li>
                <a href={'mailto:' + this.props.email}>
                  <FontAwesomeIcon icon={faEnvelope} /> {this.props.email}
                </a>
              </li>
              <li>
                <a href="tel:+447393453206">
                  <FontAwesomeIcon icon={faPhone} /> +44 7393 453206
                </a>
              </li>
            </ul>
          </Col>
          <Col sm={3} className={'pb-4'}>
            <h6>Useful links</h6>
            <ul>
              <li>
                <a href={RAPIDAPI_PROJECT_PAGE}>API Documentation</a>
              </li>
            </ul>
          </Col>
          <Col sm={4}>
            <h6>More Info</h6>
            <p className="text-light">
              <sub>
                Site design © {new Date().getFullYear()} Christopher Graney-Ward
              </sub>
            </p>
          </Col>
        </Row>
      </footer>
    );
  }
}
