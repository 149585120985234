import { url, RAPIDAPI_HOST, RAPIDAPI_KEY } from '../consts';
import axios from 'axios';

function requestBuilder(uri, params = {}, method = 'GET') {
  return axios.request({
    method,
    url: `${url}${uri}`,
    params,
    headers: {
      'X-RapidAPI-Key': RAPIDAPI_KEY,
      'X-RapidAPI-Host': RAPIDAPI_HOST,
    },
  });
}

function predictHate(text) {
  return requestBuilder('/predict/hate', { text });
}

function predictHateExplain(text) {
  return requestBuilder('/predict/hate/explain', { text });
}

function predictHateTwitter(text, type) {
  return requestBuilder(`/predict/hate/twitter/${type}/${text}`);
}

const endpoints = {
  predictHate,
  predictHateExplain,
  predictHateTwitter,
};

export default endpoints;
